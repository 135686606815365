* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Exxpanded', sans-serif;
}

img,
a {
  border: none;
  outline: none;
}
